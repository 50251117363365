<template>
  <div>
    <el-dialog :modal="false"
      title="地块设置"
      :visible.sync="landSetShow"
      :before-close="closeDg">
      <div class="del-tips-container">
        <el-form :model="landForm" :rules="rules" ref="landForm" label-width="100px" class="land-form">
          <el-form-item label="地块名称" prop="name">
            <el-col :span="11"><el-input v-model="landForm.name"></el-input></el-col>
          </el-form-item>
          <el-form-item label="地块面积" prop="area">
            <el-col :span="11">
              <el-input v-model="landForm.area"></el-input>
            </el-col>
            <el-col :span="2">亩</el-col>
            
          </el-form-item>
        </el-form>
      </div>
      <div slot="footer" class="del-tips-footer">
        <el-button type="primary" @click="closeDg">取消</el-button>
        <el-button type="primary" @click="submit">提交</el-button>
      </div>
    </el-dialog>
  </div>
</template>
<script>
export default {
  props:['landSetShow', 'area'],
  data() {
    return {
      dgShow: false,
      rules: {
        name: [
          { required: true, message: '请输入地块名称', trigger: 'blur' },
        ],
        area: [
          { required: true, message: '请输入地块面积', trigger: 'blur' },
        ]
      },
      landForm: {
        name: '',
        area: 0,
      }
    }
  },
  watch: {
    area(val) {
      this.landForm.area = val;
    }
  },
  created() {
  },
  methods: {
    closeDg() {
      this.$emit("closeSetDg", false);
    },
    submit() {
      this.$emit('submitLandInfo', this.landForm);
    }
  }
}
</script>