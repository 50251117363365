<!-- //科技管理 -->
<template>
  <div>
    <div class="total-info" v-if="landInfo">
      <img :src="landInfo.imageUrl" alt="" />
      <div class="detail-wrap">
        <div class="title">{{ landInfo.name }}</div>
        <div class="total-item">
          <template v-if="landInfo.area">
            <span class="label">地块面积：</span>
            <span class="value">{{ formatPlantArea(landInfo.area) }}亩</span>
          </template>
          <template v-if="landInfo.landformsTypeName">
            <span class="label">地貌类型：</span>
            <span class="value">{{ landInfo.landformsTypeName }}</span>
          </template>
          <template v-if="landInfo.landformsTypeName">
            <span class="label" v-if="landInfo.soilTypeName">土壤类型：</span>
            <span class="value">{{ landInfo.soilTypeName }}</span>
          </template>
          <span class="label">产量：</span>
          <span class="value"
            ><el-input placeholder="产量" @change="changeOutput" type="number" min="1" size="small" v-model="actualYield" :disabled="outputDisabled">
            </el-input
            >斤
            <el-button type="primary" icon="el-icon-edit" size="small" @click="editOutput">{{ outputDisabled ? '编辑' : '完成' }}</el-button></span
          >
        </div>
        <div class="total-item">
          <span class="label">地址：</span>
          <span class="value">{{ landInfo.province }}{{ landInfo.city }}{{ landInfo.county }}{{ landInfo.township }}{{ landInfo.village }}</span>
        </div>
        <div class="total-item" v-if="landInfo.address">
          <span class="label">详细地址：</span>
          <span class="value">{{ landInfo.address }}</span>
        </div>
      </div>
    </div>
    <el-tabs v-model="activeName">
      <el-tab-pane label="农事记录" name="1"></el-tab-pane>
      <el-tab-pane label="巡田" name="2"></el-tab-pane>
    </el-tabs>
    <farmList :landId="landId" v-if="activeName == 1" :landInfo="landInfo"></farmList>
    <patrolList :landId="landId" v-if="activeName == 2"></patrolList>
  </div>
</template>

<script>
import patrolList from './components/patrolList.vue';
import farmList from './components/farmList.vue';
import { farmViewDetail, farmUpdate } from '@/api/landManage/api.js';
import * as utils from '@/utils/utils.js';
export default {
  components: {
    farmList,
    patrolList
  },
  data() {
    return {
      landInfo: null, // 地块详情
      actualYield: 0,
      outputDisabled: true,
      activeName: '1',
      landId: ''
    };
  },
  created() {
    this.landId = this.$route.query.landId || '';
    if (this.landId) {
      // 获取地块详情
      this.getLandInfo();
    }
  },
  mounted() {},
  methods: {
    formatPlantArea(val) {
      return utils.formatArea(val);
    },
    getLandInfo() {
      farmViewDetail({ id: this.landId })
        .then((res) => {
          this.landInfo = res;
          this.actualYield = res.actualYield || 0;
        })
        .catch((error) => {});
    },
    changeOutput(val) {
      if (!val || val < 0) {
        val = 1;
      }
      this.actualYield = val;
    },
    editOutput() {
      if (!this.outputDisabled) {
        this.farmInfoUpdate();
      }
      this.outputDisabled = !this.outputDisabled;
    },
    farmInfoUpdate() {
      let params = {
        actualYield: this.actualYield,
        id: this.landId
      };
      farmUpdate(params)
        .then((res) => {
          this.$message.success('产量数据已更新');
        })
        .catch((error) => {});
    }
  }
};
</script>
<style scoped lang="scss">
.total-info {
  display: flex;
  border-bottom: 1px solid #ebedf4;
  padding-bottom: 20px;
  img {
    width: 100px;
    height: 100px;
  }
  .detail-wrap {
    padding: 0;
    margin-left: 20px;
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    .title {
      height: 16px;
      font-size: 16px;
      font-family: PingFangSC-Medium, PingFang SC;
      font-weight: 500;
      color: #2c3542;
      line-height: 16px;
    }
    .total-item {
      font-size: 14px;
      font-family: PingFangSC-Regular, PingFang SC;
      font-weight: 400;
      color: #909399;
      .label {
        width: 120px;
      }
      .value {
        color: #2c3542;
        margin-right: 40px;
        .el-input {
          width: 80px;
          display: inline-block;
          margin-right: 5px;
        }
        .el-button {
          margin-left: 10px;
        }
      }
    }
  }
}

.flex-row {
  display: flex;
  flex-direction: row;
  align-items: center;

  .span1 {
    font-size: inherit;
    font-size: 12px;
    width: 70px;
  }
}
.means-num {
  color: #409eff;
  cursor: pointer;
}
</style>
