<template>
  <div>
    <div class="map-container">
      <div class="btn-wrap">
        <el-row :gutter="20">
          <el-col :span="2">
            <el-button type="primary" @click="landAction('draw')">圈地</el-button>
          </el-col>
          <el-col :span="2">
            <el-button type="info" @click="landAction('obs')">障碍</el-button>
          </el-col>
          <el-col :span="2">
            <el-button type="warning" @click="landAction('cancel')">撤销</el-button>
          </el-col>
          <el-col :span="2">
            <el-button type="danger" @click="landAction('del')">删除</el-button>
          </el-col>
        </el-row>
      </div>
      <div class="result">
        <span>周长：{{ parseFloat(mapData.girth).toFixed(2) }}米</span>
        <span>面积：{{ formatPlantArea(mapData.area) }}亩</span>
      </div>
      <div id="map"></div>
      <div class="tips" v-if="actionTips"><span class="icon iconfont icon-tishi"></span>{{ actionTips }}</div>
      <div class="finish-confirm" v-if="floatBtnType" @click="clickFloatBtn">{{ floatBtnType == 1 ? '闭合' : '确认' }}</div>
      <div class="zoom-tool-wrap">
        <div class="tool-btn" @click="zoomAdd">
          <span class="zn-zoom-icon icon iconfont icon-jiahao"></span>
        </div>
        <div class="tool-btn" @click="zoomCut">
          <span class="zn-zoom-icon icon iconfont icon-jianhao"></span>
        </div>
      </div>
    </div>

    <el-dialog :modal="false" title="删除提示" :visible.sync="dgDelVisible">
      <div class="del-tips-container">
        第一步：点击上方“删除”按钮；<br />
        第二步：点击要删除的地块轮廓；
      </div>
      <div slot="footer" class="del-tips-footer">
        <el-button type="primary" @click="dgDelVisible = false">我知道了</el-button>
      </div>
    </el-dialog>

    <!-- <el-dialog :modal="false" :width="'400px'" :center="true" :show-close="false" :visible.sync="delConfirmDg">
      <div slot="title" class="del-confirm-title">
        确认删除地块？
      </div>
      <div class="del-confirm-content">
        删除后地块相关记录将无法恢复
      </div>
      <div slot="footer" class="del-confirm-footer">
        <div class="del-confirm-footer-btn cancel">取消</div>
        <div class="del-confirm-footer-btn confirm">确认</div>
      </div>
    </el-dialog> -->
    <!-- 输入地块名字及面积弹窗 -->
    <land-set :land-set-show="landSetShow" :area="mapData.area" @closeSetDg="toggleSetDg" @submitLandInfo="submitLandInfo"></land-set>

    <el-dialog
      :modal="false"
      :width="'400px'"
      :center="true"
      title="选择障碍物"
      :show-close="false"
      :visible.sync="selObsDg"
      :close-on-click-modal="false"
      :close-on-press-escape="false"
    >
      <div class="del-confirm-content">
        <el-select v-model="selObsType" placeholder="请选择" @change="obsChange">
          <el-option v-for="item in obsImages" :key="item.type" :label="item.name" :value="item.type"> </el-option>
        </el-select>
      </div>
    </el-dialog>
  </div>
</template>
<script>
import L from 'leaflet';
import 'leaflet-draw';
import '@/config/zoomControl.js';
import 'leaflet/dist/leaflet.css';
import 'leaflet-draw/dist/leaflet.draw.css';
import '@/config/leaflet.draw-cn.js';
import { googleMap, gaodeMap } from '@/config/map.js';
import * as utils from '@/utils/utils.js';
import { landAdd, allLandInfos } from '@/api/api.js';
import landSet from './landSet';
export default {
  props: ['landDgVisible', 'type', 'editObj', 'obsImages'],
  components: {
    'land-set': landSet
  },
  data() {
    return {
      drawNum: 0, // 圈地数量
      drawObsNum: 0, // 画的障碍物数量
      drawControl: '', //地图工具条
      drawPolyline: '', //绘制多边形对象
      drawNum: 0, //0是画地块，大于0是障碍物
      marker: [], // 地图标记
      latlngs: [], //地块经纬度数组
      locLat: 33.446385, // 当前的维度,
      locLng: 114.071285, // 当前的经度
      polygon: '', //地块对象
      editingPolygon: '', // 正在操作的地块对象
      polygonArr: [], //地块集合数组
      polygonGroup: '', //地块集合图层
      obsPolygon: '', //障碍物对象
      editingObsPolygon: '', // 正在操作的障碍物对象
      obsPolygonArr: [], //障碍物集合数组
      obsPolygonGroup: '', //障碍物集合图层
      obstaclesMarker: [], //障碍物icon数组
      polygonLineColor: '#B2FFB2', // 已有地块的多边形的边框颜色
      polygonFillColor: '#8DFF9B', // 已有地块的多边形的填充颜色
      editingPolygonLineColor: '#1890FF', // 正在编辑操作的多边形的边框颜色
      editingPolygonFillColor: '#1890FF', // 正在编辑操作的多边形的填充颜色
      dgDelVisible: false,
      showDelGuide: false, // 是否展示删除引导
      drawedVertexNum: 0, // 绘制的顶点个数
      drawCreatedFlag: false, //是否绘制完成
      currentActionName: '', // 当前正在操作的按钮
      actionTips: '操作提示：点击上方按钮，即可对地块进行编辑',
      area: 0, // 地块面积(亩)
      girth: 0, // 周长
      landSetShow: false,
      delConfirmDg: false, // 删除确认弹窗
      selObsDg: false, // 选择障碍物弹窗
      selObsType: '', // 选择的障碍物类型
      mapData: {
        name: '',
        code: '1001',
        girth: 0,
        area: 0,
        centerLat: 0, //当前定位的纬度
        centerLon: 0, //当前定位的经度
        boundaries: [], // 地块边界数组
        obstacleBoundaries: [] // 障碍物边界数组
      },
      editObsBoundary: {
        // 当前正在操作的障碍物对象
        points: [],
        area: 0, //地块面积(平方米)
        muNum: 0, // 地块面积(亩)
        girth: 0, //地块周长
        latitude: 0, //纬度
        longitude: 0, //经度
        name: ''
      },
      currBoundary: {
        // 当前正在画的地块信息
        labelids: [],
        name: '', // 边界名字
        descriptions: '', //边界描述
        subType: 1, //边界类型 1 标准地块
        longitude: 0, //中心点经度  必填
        latitude: 0, //中心点纬度  必填
        area: 0, //地块面积(平方米)
        muNum: 0, // 地块面积(亩)
        girth: 0, // 周长
        province: '', //地址 ： 省   非必填
        city: '', //地址 ： 省   非必填
        county: '', //地址 ： 省   非必填
        township: '', //地址 ： 乡   非必填
        village: '', //地址 ： 村   非必填
        address: '', //详细地址   非必填
        points: []
      },
      landData: []
    };
  },
  mounted() {
    if (this.type == 'edit') {
      this.locLat = this.editObj.latitude;
      this.locLng = this.editObj.longitude;
      this.landId = this.editObj.landId;
    }
    this.initMap();
  },
  computed: {
    // 悬浮按钮显示按钮类型（0: 不显示 1: 闭合 2: 确认）
    floatBtnType() {
      let type = 0;
      if (this.drawCreatedFlag || this.type == 'edit') {
        type = 2;
      } else if (this.drawedVertexNum > 2) {
        type = 1;
        this.actionTips = '操作提示：点击第一个点结束';
      }
      return type;
    }
  },
  methods: {
    formatPlantArea(val) {
      return utils.formatArea(val);
    },
    initMap() {
      // 地图初始化参数
      let initParam = {
        L,
        id: 'map',
        center: [this.locLat, this.locLng],
        mapType: googleMap,
        zoom: this.type == 'edit' ? 13 : 12
      };
      // 初始化地图
      this.map = utils.initMap(initParam);
      this.onMapDrawEvent();
      // 获取地块列表
      this.getLandList();
    },
    getLandList() {
      allLandInfos()
        .then((res) => {
          this.landData = res;
          this.showLands();
        })
        .catch((err) => {
          console.error(err);
        });
    },
    // 获取地块分组信息
    // getLandGroup(id) {
    // let params = {
    //   "name": "高标准示范田"
    // }
    // },
    // 展示所有地块
    showLands() {
      let landData = this.landData;
      if (landData.length) {
        landData.forEach((land) => {
          if (land.id == this.landId) {
            this.mapData = Object.assign(this.mapData, land);
            this.mapData.boundaries = [];
            this.mapData.obstacleBoundaries = [];
          }
          let boundaries = land.boundaries || [];
          // 绘制地块
          boundaries.forEach((bound, index) => {
            let latLngs = [];
            bound.biasedBoundary.forEach((point) => {
              latLngs.push([point.latitude, point.longitude]);
            });
            if (land.id == this.landId) {
              bound['points'] = bound.biasedBoundary;
              delete bound.biasedBoundary;
              this.mapData.boundaries.push(bound);
            }
            let polygon = L.polygon(latLngs, {
              color: land.id == this.landId ? this.editingPolygonLineColor : this.polygonLineColor,
              fillColor: land.id == this.landId ? this.editingPolygonFillColor : this.polygonFillColor,
              weight: 1,
              opacity: 1,
              fillOpacity: 0.3,
              _type: 1, // 地块
              _id: bound.id,
              landId: land.id,
              _index: index
            })
              .addTo(this.map)
              .on('click', (e) => {
                let target = e.target;
                if (target.options.landId == this.landId) {
                  if (this.canDelete) {
                    this.delTimer && clearTimeout(this.delTimer);
                    this.delTimer = setTimeout(() => {
                      this.$message.warning('至少保留一个地块');
                      return;
                      this.$confirm('删除后地块相关记录将无法恢复', '确认删除地块？')
                        .then(() => {
                          this.removeLandLayer(target, true);
                          let deletedArr = this.mapData.boundaries.filter((item) => item.id != target.options._id);
                          this.mapData.boundaries = deletedArr;
                          if (!deletedArr.length) {
                            this.mapData.centerLat = 0;
                            this.mapData.centerLon = 0;
                            this.clearLayers();
                          }
                        })
                        .catch(() => {});
                    }, 200);
                  }
                }
              });
            // 得到想要编辑的地块对象
            if (land.id == this.landId) {
              this.editingPolygon = polygon;
            }
            this.polygonArr.push(polygon);
          });
          // 绘制障碍物
          let obstacleBoundaries = land.obstacleBoundaries || [];
          // 绘制地块
          obstacleBoundaries.forEach((bound, index) => {
            let obsLatLngs = [];
            bound.biasedBoundary.forEach((point) => {
              obsLatLngs.push([point.latitude, point.longitude]);
            });
            if (land.id == this.landId) {
              bound['points'] = bound.biasedBoundary;
              delete bound.biasedBoundary;
              this.mapData.obstacleBoundaries.push(bound);
            }
            let obsPolygon = L.polygon(obsLatLngs, {
              color: '#FFFFFF',
              fillColor: '#FFFFFF',
              weight: 1,
              opacity: 1,
              fillOpacity: 0.3,
              dashArray: [5, 5],
              _type: 2, // 障碍物
              _id: bound.id,
              _index: index
            })
              .addTo(this.map)
              .on('click', (e) => {
                this.delTimer && clearTimeout(this.delTimer);
                this.delTimer = setTimeout(() => {
                  let target = e.target;
                  this.removeObsLayer(target);
                  this.removeObsMarkerLayer(target._leaflet_id);
                  this.mapData.obstacleBoundaries = this.mapData.obstacleBoundaries.filter((item) => item.id != target.options._id);
                  console.log('删除后地块数据', JSON.stringify(this.mapData.boundaries));
                }, 100);
              });

            // 障碍物标记
            if (bound.obstacleImgUrl) {
              var myIcon = L.icon({
                iconUrl: bound.obstacleImgUrl,
                iconSize: [20]
              });
              let obsCenter = obsPolygon.getBounds().getCenter();
              let layer = L.marker([obsCenter.lat, obsCenter.lng], {
                id: 'land',
                icon: myIcon,
                title: 'title',
                alt: 'alt',
                opacity: '1',
                _id: obsPolygon._leaflet_id
              }).addTo(this.map);
              this.obsPolygonArr.push(obsPolygon);
              this.obstaclesMarker.push(layer);
              this.obsMarkerGroup = L.layerGroup(this.obstaclesMarker);
              this.map.addLayer(this.obsMarkerGroup);
            }
          });
        });
      }
      this.polygonGroup = L.layerGroup(this.polygonArr);
      this.map.addLayer(this.polygonGroup);
      this.obsPolygonGroup = L.layerGroup(this.obsPolygonArr);
      this.map.addLayer(this.obsPolygonGroup);

      // 编辑地块时使对应地块激活
      if (this.type == 'edit') {
        this.editingPolygon.editing.enable();
      }
    },
    clearLayers() {
      // 清空障碍物layer
      if (this.obsPolygonGroup) {
        this.obsPolygonGroup.clearLayers();
        this.obsPolygonArr = [];
        this.mapData.obstacleBoundaries = [];
      }
      // 清空障碍物marker
      if (this.obsMarkerGroup) {
        this.obstaclesMarker = [];
        this.obsMarkerGroup.clearLayers();
      }
    },
    // 绘制完成
    drawCreated(e) {
      this.createdMap(e);
      // 绘图已完成
      this.drawCreatedFlag = true;
      // 绘制顶点个数置为0
      this.drawedVertexNum = 0;
      this.actionTips = '操作提示：拖动黄点调增地块边界';
    },
    // 编辑顶点
    drawEditvertex(e) {
      console.log('编辑顶点操作开始了');
      this.editvertex(e);
      this.drawCreatedFlag = true;
    },
    // 绘制顶点
    drawVertex(e) {
      this.drawvertex(e);
      if (this.currentActionName == 'obs') {
        this.drawedObsVertexNum = Object.keys(e.layers._layers).length;
      } else {
        this.drawedVertexNum = Object.keys(e.layers._layers).length;
      }
    },
    zoomAdd() {
      this.map && this.map.setZoom(this.map.getZoom() + 1);
    },
    zoomCut() {
      this.map && this.map.setZoom(this.map.getZoom() - 1);
    },
    // 地图缩放结束
    zoomEnd(e) {
      let bound = this.map.getCenter();
      console.log(bound);
    },
    // 监听地图的画图事件
    onMapDrawEvent() {
      this.map.on('draw:created', this.drawCreated);
      this.map.on('draw:editvertex', this.drawEditvertex);
      this.map.on('draw:drawvertex', this.drawVertex);
      // this.map.on('draw:drawvertex', this.drawVertex);
      // 当地图缩放改变时触发，在任何动画之后。
      this.map.on('zoomend', this.zoomEnd);
    },
    // 图形绘制完成
    createdMap(e) {
      let that = this;
      // 各个边长
      that.marker = [];
      if (that.markerGroup) that.markerGroup.clearLayers();
      // 绘制完成
      this.isDrawing = false;
      //绘制完后的经纬度数组
      var latLngs = e.layer._latlngs[0];
      // 画地块数量（一次仅支持画一个）
      if (that.drawNum == 0 && this.currentActionName == 'draw') {
        // 画地块计算周长和面积
        that.computeGirthAndArea(latLngs, 1);

        that.editingPolygon = L.polygon(that.latlngs, {
          color: that.polygonLineColor,
          fillColor: that.polygonFillColor,
          weight: 1,
          opacity: 1,
          fillOpacity: 0.3,
          _type: 1, // 地块
          _id: this.drawNum,
          _index: this.drawNum
        })
          .addTo(that.map)
          .on('click', (e) => {
            console.log('执行了一次');
            if (this.canDelete) {
              // 防止连击
              this.delTimer && clearTimeout(this.delTimer);
              this.delTimer = setTimeout(() => {
                this.$confirm('删除后地块相关记录将无法恢复', '确认删除地块？')
                  .then(() => {
                    this.removeLandLayer(e.target);
                  })
                  .catch(() => {});
              }, 100);
            }
          });
        // // 获取中心点坐标
        let { lat, lng } = that.editingPolygon.getBounds().getCenter();
        that.currBoundary.latitude = lat;
        that.currBoundary.longitude = lng;
        this.mapData.boundaries.push(this.currBoundary);
        this.mapData.centerLat = lat;
        this.mapData.centerLon = lng;
        that.polygonArr.push(that.editingPolygon);
        that.polygonGroup = L.layerGroup(that.polygonArr);
        that.createdLeafletId = that.polygonGroup.getLayerId(that.editingPolygon);
        that.map.addLayer(that.editingPolygon);
        setTimeout(() => {
          that.editingPolygon.editing.enable();
        }, 20);
        that.drawNum++;
      } else if (this.currentActionName == 'obs') {
        // 障碍物计算周长和面积
        that.computeGirthAndArea(latLngs, 2);
        that.editingObsPolygon = L.polygon(that.latlngs, {
          color: '#FFFFFF',
          fillColor: '#FFFFFF',
          weight: 1,
          opacity: 1,
          fillOpacity: 0.3,
          dashArray: [5, 5],
          _type: 2, // 障碍物
          _id: this.drawObsNum,
          _index: this.drawObsNum
        })
          .addTo(that.map)
          .on('click', (e) => {
            if (this.canDelete) {
              // 防止连击
              this.delTimer && clearTimeout(this.delTimer);
              this.delTimer = setTimeout(() => {
                let target = e.target;
                this.removeObsLayer(target);
                this.removeObsMarkerLayer(target._leaflet_id);
              }, 100);
            }
          });
        let { lat, lng } = that.editingObsPolygon.getBounds().getCenter();
        that.editObsBoundary.latitude = lat;
        that.editObsBoundary.longitude = lng;
        this.selObsDg = true;
        this.mapData.obstacleBoundaries.push(this.editObsBoundary);
        that.obsPolygonArr.push(that.editingObsPolygon);
        that.obsPolygonGroup = L.layerGroup(that.obsPolygonArr).setZIndex(999);
        that.map.addLayer(that.obsPolygonGroup);

        that.editingObsPolygon.editing.enable();
        that.drawObsNum++;
      }
    },
    selObsIcon(obj = {}) {
      if (obj.type) {
        this.editObsBoundary.subType = obj.type;
        let lat = this.editObsBoundary.latitude;
        let lng = this.editObsBoundary.longitude;
        // 障碍物标记
        var myIcon = L.icon({
          iconUrl: obj.showImageUrl,
          iconSize: [20]
        });
        let layer = L.marker([lat, lng], {
          id: 'land',
          icon: myIcon,
          title: 'title',
          alt: 'alt',
          opacity: '1',
          _id: this.editingObsPolygon._leaflet_id
        }).addTo(this.map);
        this.obstaclesMarker.push(layer);
        this.obsMarkerGroup = L.layerGroup(this.obstaclesMarker);
        this.map.addLayer(this.obsMarkerGroup);
        this.editObsBoundary.subType = obj.type;
        this.selObsDg = false;
        this.selObsType = '';
      }
    },
    // 移除地块layer
    removeLandLayer(polygon, delEditLand) {
      this.polygonArr = this.polygonArr.filter((item) => {
        return item != polygon;
      });
      this.map.removeLayer(polygon);
      if (delEditLand) {
        this.delEditLand = true;
      } else {
        this.drawNum--;
        this.drawCreatedFlag = false;
        this.currBoundary = {
          points: [],
          area: 0, //地块面积(平方米)
          muNum: 0, // 地块面积(亩)
          girth: 0, //地块周长
          latitude: 0, //纬度
          longitude: 0 //经度
        };
        this.mapData = {
          girth: 0,
          area: 0,
          centerLat: 0, //当前定位的纬度
          centerLon: 0, //当前定位的经度
          boundaries: [], // 地块边界数组
          obstacleBoundaries: [] // 障碍物边界数组
        };
      }
      // this.updateEditAreaAndPerimeter(0, 0);
    },
    // 移除障碍物layer
    removeObsLayer(obsPolygon) {
      this.obsPolygonArr = this.obsPolygonArr.filter((item) => {
        return item != obsPolygon;
      });

      // 同步更新mapData中数据
      let mapObstacleBoundaries = this.mapData.obstacleBoundaries;
      this.mapData.obstacleBoundaries = mapObstacleBoundaries.filter((item) => {
        return item.id != obsPolygon.options._id;
      });
      this.map.removeLayer(obsPolygon);
    },
    // 移除障碍物的marker标记
    removeObsMarkerLayer(leafletId) {
      if (this.obstaclesMarker.length) {
        this.obstaclesMarker.forEach((item, index) => {
          if (item.options._id == leafletId) {
            this.obsMarkerGroup.removeLayer(item);
            this.obstaclesMarker.splice(index, 1);
          }
        });
      }
    },
    editvertex(e) {
      let poly = e.poly;

      let latlngs = poly._latlngs[0],
        type = poly.options._type;
      if (type == 2) {
        this.editingObsPolygon = poly;
      } else {
        this.editingPolygon = poly;
      }
      this.computeGirthAndArea(latlngs, type, true);
    },
    // 绘制顶点
    drawvertex(e) {
      // 各个边长
      this.marker = [];
      if (this.markerGroup) this.markerGroup.clearLayers();

      let values = Object.values(e.layers._layers);
      let arr = [];
      values.forEach((v, i) => {
        arr.push(v._latlng);
      });
      if (arr.length > 1) {
        arr.map((item, idx) => {
          if (idx < arr.length - 1) {
            let pt1 = [item.lat, item.lng];
            let pt2 = [arr[idx + 1].lat, arr[idx + 1].lng];
            this.returnSlideDistance(this.map, pt1, pt2);
          }
        });
      }
    },
    // 计算多边形周长和面积 editvertex:是否是编辑多边形 type(1:地块,2:障碍物)
    computeGirthAndArea(latlngs, type, editvertex) {
      // 面积参数
      let path = [];
      this.latlngs = [];
      let points = [];
      if (latlngs.length > 1) {
        latlngs.forEach((item, index) => {
          // 地块描点对应数组放值
          this.latlngs.push([item.lat, item.lng]);
          //面积参数
          path.push({
            lat: item.lat,
            lng: item.lng
          });
          points.push({
            longitude: item.lng,
            latitude: item.lat
          });
          if (index < latlngs.length - 1 && editvertex && type == 1) {
            let pt1 = [item.lat, item.lng];
            let pt2 = [latlngs[index + 1].lat, latlngs[index + 1].lng];
            this.returnSlideDistance(this.map, pt1, pt2, true);
          }
        });
        this.isCorssing = utils.pointsIsCrossing(latlngs);
        if (this.isCorssing) {
          this.$message('您的地块边界存在交叉,请调节');
        } else {
          this.isCorssing = false;
        }
        // 第一个点在数组末尾重新放置一个
        points.push(points[0]);
      }
      if (type == 1) {
        this.currBoundary.points = points;
        // 计算面积
        this.currBoundary.area = this.computeSignedArea(path);
        this.mapData.girth = this.currBoundary.girth;
        if (editvertex) {
          // 获取中心点坐标
          let { lat, lng } = this.editingPolygon.getBounds().getCenter();
          this.currBoundary.latitude = lat;
          this.currBoundary.longitude = lng;
          this.mapData.centerLat = lat;
          this.mapData.centerLon = lng;
          this.mapData.boundaries[this.editingPolygon.options._index].latitude = lat;
          this.mapData.boundaries[this.editingPolygon.options._index].longitude = lng;
          this.mapData.boundaries[this.editingPolygon.options._index].points = points;
          this.mapData.boundaries[this.editingPolygon.options._index].area = this.currBoundary.area;
        }
        // 更新面积和周长
        this.updateEditAreaAndPerimeter(this.currBoundary.girth, this.currBoundary.area);
      } else if (type == 2) {
        if (editvertex) {
          // 编辑障碍物
          // 获取中心点坐标
          let { lat, lng } = this.editingObsPolygon.getBounds().getCenter();
          this.mapData.obstacleBoundaries[this.editingObsPolygon.options._index].latitude = lat;
          this.mapData.obstacleBoundaries[this.editingObsPolygon.options._index].longitude = lng;
          this.mapData.obstacleBoundaries[this.editingObsPolygon.options._index].points = points;
          this.mapData.obstacleBoundaries[this.editingObsPolygon.options._index].area = this.computeSignedArea(path);
        } else {
          // 新建的障碍物
          this.editObsBoundary.points = points;
          // 计算面积
          this.editObsBoundary.area = this.computeSignedArea(path);
        }
      }
    },
    // 计算多边形每条边的长度
    returnSlideDistance(map, pt1, pt2, editvertex) {
      let length = map.distance(pt1, pt2);
      let distance = Math.abs(parseFloat(length).toFixed(2));
      let lat = (Number(pt1[0]) + Number(pt2[0])) / 2;
      let lng = (Number(pt1[1]) + Number(pt2[1])) / 2;
      if (this.currentActionName == 'obs') {
        this.editObsBoundary.girth += distance;
      } else {
        this.currBoundary.girth += distance;
      }
      if (!editvertex) {
        let icon = L.divIcon({
          className: 'distanceContent',
          html: `<span class="distanceTip">${distance}米</span>`,
          iconSize: [0, 0]
        });
        let layer = L.marker([lat, lng], { icon: icon }).addTo(map);
        this.marker.push(layer);
        this.markerGroup = L.layerGroup(this.marker);
        map.addLayer(this.markerGroup);
        // this.updateEditAreaAndPerimeter(this.currBoundary.girth + '米','0亩');
      }
    },
    // 计算地块面积
    computeSignedArea(path) {
      let that = this;
      let radius = 6371009;
      let len = path.length;
      if (len < 3) return 0;
      let total = 0;
      let prev = path[len - 1];
      let prevTanLat = Math.tan((Math.PI / 2 - (prev.lat / 180) * Math.PI) / 2);
      let prevLng = (prev.lng / 180) * Math.PI;
      for (let i in path) {
        let tanLat = Math.tan((Math.PI / 2 - (path[i].lat / 180) * Math.PI) / 2);
        let lng = (path[i].lng / 180) * Math.PI;
        total += that.polarTriangleArea(tanLat, lng, prevTanLat, prevLng);
        prevTanLat = tanLat;
        prevLng = lng;
      }
      return utils.unitConverter(parseFloat(Math.abs(total * (radius * radius)).toFixed(2)));
    },
    polarTriangleArea(tan1, lng1, tan2, lng2) {
      let deltaLng = lng1 - lng2;
      let t = tan1 * tan2;
      return 2 * Math.atan2(t * Math.sin(deltaLng), 1 + t * Math.cos(deltaLng));
    },
    // 按钮操作
    landAction(actionName) {
      // this.canDelete = false;
      // this.delConfirmDg = true;
      // return;
      // this.landSetShow = true;
      // return;
      if (actionName == 'del') {
        if (this.isDrawing) {
          this.$message('绘图过程中不能删除');
          return;
        }
        if (this.type == 'edit' && this.delEditLand) {
          this.$message('只能删除正在编辑的地块');
          return;
        }

        if (this.drawNum || this.type == 'edit') {
          this.canDelete = true;
          this.dgDelVisible = true;
          this.editingPolygon && this.editingPolygon.editing.disable();
          this.editingObsPolygon && this.editingObsPolygon.editing.disable();
          this.currentActionName = actionName;
        } else {
          this.$message('请先绘制地块');
        }
      } else if (actionName == 'cancel') {
        if (this.isDrawing) {
          if (this.drawedVertexNum > 1 || this.drawedObsVertexNum > 1) {
            this.drawPolyline.deleteLastVertex();
          }
        } else {
          this.$message('只有在绘制过程中才可撤销！');
        }
      } else if (actionName == 'obs') {
        if (this.drawNum || this.type == 'edit') {
          this.isDrawing = true;
          if (!this.drawPolyline) {
            this.drawPolyline = new L.Draw.Polygon(this.map, false);
          }
          this.drawPolyline.enable();
          this.editingPolygon.editing.disable();
          this.currentActionName = actionName;
        } else {
          this.$message('请先绘制地块');
        }
      } else {
        if (this.type == 'edit') {
          this.$message('编辑过程中暂不能圈地');
          return;
        }
        if (this.drawNum > 0) {
          this.$message('最多绘制一个地块');
          return;
        }
        this.isDrawing = true;
        if (!this.drawPolyline) {
          this.drawPolyline = new L.Draw.Polygon(this.map, false);
        }
        this.drawPolyline.enable();
        this.currentActionName = actionName;
        // 地图加载出来后展示提示消息
        this.actionTips = '操作提示：请在你的地块边缘打点';
      }
    },
    // 浮动按钮点击事件 1: 闭合  2: 绘图确认
    clickFloatBtn() {
      if (this.isCorssing) {
        this.$message('您的地块边界存在交叉,请调节');
        return;
      }
      let type = this.floatBtnType;
      if (type == 1) {
        // 闭合
        this.drawPolyline.completeShape();
        this.actionTips = '操作提示：拖动黄点调增地块边界';
      } else if (type == 2) {
        // if (this.mapData.area > 10000) {
        //   this.$message('地块面积请勿超过10000亩');
        //   return;
        // }
        if (this.type == 'edit') {
          this.$emit('closeLandDg', this.mapData);
        } else {
          this.landSetShow = true;
          this.actionTips = '';
        }
      }
    },
    // 更新面积和周长(同步)
    updateEditAreaAndPerimeter(perimeter, area) {
      // if (area > 10000 && !this.isCorssing) {
      //   this.$message('地块面积请勿超过10000亩');
      // }
      this.mapData.girth = perimeter;
      this.mapData.area = area;
    },
    // 删除地块是否展示引导（同步）
    isShowcase(count = 3) {
      let res = this.$dsBridge.call('isShowcase', { count });
      let flag = 0;
      if (res) {
        flag = JSON.parse(res).data.flag;
      }
      return flag;
    },
    // 弹出地块设置信息弹窗（同步）
    toggleSetDg() {
      this.landSetShow = !this.landSetShow;
    },
    // 定位到当前位置
    dingwei(lat = '39.89', lng = '116.45') {
      // 指定中心点
      let center = L.latLng(lat, lng);
      var locationIcon = L.icon({
        iconUrl: 'static/images/location.png',
        iconSize: [20, 24],
        iconAnchor: [10, 24]
      });
      // 如果当前位置marker已标记则删除重新标记
      if (this.locIconMarker) {
        this.location.remove();
      }
      // 视图位置的icon标志
      this.locIconMarker = L.marker(center, { icon: locationIcon }).addTo(this.map);
      this.resetMapView(center);
    },
    // 更新视图视角
    resetMapView(center, zoom = 16) {
      this.map.setView(center, zoom);
    },
    // 删除引导的知道了按钮操作
    know() {
      this.showDelGuide = false;
      this.canDelete = true;
      this.clearTimer();
    },
    clearTimer() {
      this.guideTimer && clearInterval(this.guideTimer);
    },
    // 获取位置信息 (js-native) 异步
    getLocation(action) {
      if (!action) {
        // 获取位置信息 (js-native) 异步
        this.$dsBridge.call('getLocation', null, (res) => {
          let { latitude, longitude } = JSON.parse(res).data;
          this.locLat = latitude;
          this.locLng = longitude;
          // 初始化地图
          this.initMap();
        });
      } else {
        // 获取位置信息 (js-native) 异步
        this.$dsBridge.call('getLocation', null, (res) => {
          let { latitude, longitude } = JSON.parse(res).data;
          // 初始化地图
          this.dingwei(latitude, longitude);
        });
      }
    },
    // 提交地块信息
    submitLandInfo(param) {
      let params = this.mapData;
      params.name = param.name;
      params.area = param.area;
      landAdd(params)
        .then((res) => {
          // 关闭所有弹窗，刷新地块列表接口
          this.landSetShow = false;
          this.$emit('closeLandDg');
          this.$emit('refreshLand');
        })
        .catch((error) => {});
    },
    // 搜索地址信息回调
    updateLocation(param) {
      let { latitude, longitude } = JSON.parse(param);
      // 指定中心点
      let center = L.latLng(latitude, longitude);
      this.resetMapView(center);
    },
    //注册 javascript API 供原生调用
    registerJsApi() {
      /**
       * 搜索获取位置信息，更新地图(native-js) 同步
       * latitude
       * longitude
       * address
       * poi
       */
      this.$dsBridge.register('updateLocation', (param) => {
        this.updateLocation(param);
      });
      // 更新地块绘制颜色
      this.$dsBridge.register('updateLandColor', (param) => {});
      // 提交画地块信息
      this.$dsBridge.registerAsyn('submitLandInfo', (param, cb) => {
        this.submitLandInfo(param, cb);
      });
    },
    // 选择了障碍物
    obsChange() {
      let selObs = {};
      this.obsImages.forEach((item) => {
        if (item.type == this.selObsType) {
          selObs = item;
        }
      });
      this.selObsIcon(selObs);
    },
    // 数据清空
    celarData() {
      this.editingPolygon = '';
      this.polygonArr = [];
      this.polygonGroup = '';
      this.obsPolygon = '';
      this.editingObsPolygon = '';
      this.obsPolygonArr = [];
      this.obsPolygonGroup = '';
      this.obstaclesMarker = [];
    }
  },
  destroyed() {
    this.clearTimer();
    this.map && this.map.remove();
  }
};
</script>
<style scoped>
/** 地图相关 */
#map {
  width: 960px;
  height: 500px;
  margin-top: 0px;
  position: relative;
  z-index: 1;
}
#map >>> .leaflet-control-zoom {
  margin-bottom: 140px;
}

#map >>> .distanceTip {
  display: inline-block;
  word-break: keep-all;
  background-color: rgba(255, 255, 255, 0.7);
  padding: 1px 3px;
  line-height: 1;
  border-radius: 2px;
  transform: translate(-50%, -150%);
  position: relative;
  z-index: -1;
  font-size: 12px;
}
#map >>> .leaflet-draw-tooltip {
  display: none;
}
#map >>> .leaflet-div-icon {
  width: 16px !important;
  height: 16px !important;
  border-radius: 16px !important;
  background: #ffba00;
  border: 2px solid #ffffff;
}
#map >>> .leaflet-div-icon::after {
  content: '';
  width: 16px;
  height: 16px;
  position: absolute;
  left: -5px;
  top: -5px;
  display: inline-block;
}
#map >>> .zn-leaflet-control-zoom {
  position: absolute;
  bottom: 15px;
  left: 0px;
}
#map >>> .leaflet-bottom {
  z-index: 9999 !important;
}

#map >>> .zn-zoom-a {
  display: block;
  width: 40px;
  height: 40px;
  background: #ffffff;
  border-radius: 4px;
  text-align: center;
  line-height: 40px;
  margin-bottom: 5px;
  text-decoration: none;
}
#map >>> .zn-zoom-icon {
  color: #000;
  font-size: 22px;
}
</style>
<style scoped lang="scss">
.btn-wrap {
  margin-bottom: 10px;
}
.result {
  height: 30px;
  line-height: 30px;
  margin-bottom: 10px;
  color: #333;
  span {
    margin-right: 20px;
  }
}

.map-container {
  position: relative;
  .tips {
    // width: 375px;
    height: 30px;
    line-height: 30px;
    background: rgba(2, 185, 143, 0.4);
    font-size: 14px;
    font-family: PingFangSC-Regular, PingFang SC;
    font-weight: 400;
    color: #ffffff;
    padding-left: 20px;
    position: absolute;
    top: 90px;
    z-index: 1;
    width: 100%;
    .icon {
      width: 17px;
      height: 17px;
      margin-right: 5px;
    }
  }
  .zoom-tool-wrap {
    position: absolute;
    left: 10px;
    bottom: 140px;
    z-index: 2;
    .tool-btn {
      display: block;
      width: 40px;
      height: 40px;
      background: #ffffff;
      border-radius: 4px;
      text-align: center;
      line-height: 40px;
      margin-bottom: 5px;
      text-decoration: none;
      cursor: pointer;
      a {
      }
    }
  }
}

.finish-confirm {
  width: 80px;
  height: 40px;
  background: #02b98f;
  border-radius: 20px;
  font-size: 15px;
  font-family: PingFangSC-Medium, PingFang SC;
  font-weight: 500;
  color: #ffffff;
  line-height: 40px;
  text-align: center;
  position: absolute;
  bottom: 70px;
  right: 20px;
  z-index: 1;
  cursor: pointer;
}
.test {
  bottom: 240px;
}

.del-tips-footer {
  text-align: center;
}
.del-confirm-title {
  height: 18px;
  font-size: 18px;
  font-family: PingFangSC-Medium, PingFang SC;
  font-weight: 500;
  color: #303133;
  line-height: 18px;
}
.del-confirm-content {
  height: 24px;
  font-size: 14px;
  font-family: PingFang-SC-Medium, PingFang-SC;
  font-weight: 500;
  color: #606266;
  line-height: 24px;
  text-align: center;
}
.del-confirm-footer {
  display: flex;
  align-items: center;
  justify-content: space-around;
  .del-confirm-footer-btn {
    background: #ffffff;
    width: 120px;
    height: 40px;
    border-radius: 20px;
    line-height: 40px;
    box-sizing: border-box;
    cursor: pointer;
  }
  .cancel {
    border: 1px solid #dcdfe6;
  }
  .confirm {
    background: #02b98f;
    color: #ffffff;
  }
}
</style>
