<!-- //科技管理 -->
<template>
  <div>
    <el-table :data="tableData" stripe style="width: 100%" header-cell-class-name="header-cell-bg" header-row-class-name="header-bg">
      <template slot="empty">
        <img style="width: 100%; height: 100%" src="@/assets/empty.jpeg" alt="" />
      </template>
      <el-table-column prop="landName" label="地块名称"> </el-table-column>
      <el-table-column prop="landArea" label="地块面积"> </el-table-column>
      <el-table-column prop="farmingTypeName" label="巡田时间" width="200">
        <template slot-scope="scope"> {{ scope.row.realBeginDate | filterDate }}-{{ scope.row.realEndDate | filterDate }} </template>
      </el-table-column>
      <el-table-column prop="userName" label="操作人"> </el-table-column>
      <el-table-column prop="conclusionName" label="田情上报"> </el-table-column>
      <el-table-column prop="descriptions" show-overflow-tooltip label="备注"> </el-table-column>
      <!-- <el-table-column label="影像">
        <template slot-scope="scope">
          <img :src="scope.row.imageUrls[0]" v-if="scope.row.imageUrls.length"/>
        </template>
      </el-table-column> -->
    </el-table>

    <div class="pagenation">
      <el-pagination
        v-if="pageInfo.totalPage > 0"
        background
        @current-change="handleSizeChange"
        :current-page.sync="pageInfo.currentPage"
        :page-size="pageInfo.pageSize"
        layout="total, prev, pager, next"
        :total="pageInfo.totalPage"
      >
      </el-pagination>
    </div>
  </div>
</template>

<script>
import { patrolList } from '@/api/api.js';

import dayjs from 'dayjs';
export default {
  components: {},
  props: {
    landId: ''
  },
  data() {
    return {
      dialogVisible: false,
      searchDate: [],
      userId: '',
      pageInfo: {
        currentPage: 1,
        pageSize: 15,
        totalPage: 0
      },
      tableData: []
    };
  },
  created() {
    this.getList();
  },
  mounted() {},
  filters: {
    filterDate(val) {
      return dayjs(val).format('YYYY.MM.DD');
    }
  },
  methods: {
    getList(pageNum) {
      if (pageNum) {
        this.pageInfo.currentPage = pageNum;
      }
      patrolList({
        pageNum: this.pageInfo.currentPage,
        pageSize: this.pageInfo.pageSize,
        landId: this.landId
      })
        .then((res) => {
          this.tableData = res.rows;
          this.pageInfo.totalPage = res.total;
        })
        .catch((error) => {});
    },
    handleSizeChange(e) {
      this.pageInfo.currentPage = e;
      this.getList();
    },

    userChange(val) {
      this.userId = val;
    },
    landChange(val) {
      this.landId = val;
    },
    search() {
      this.searchParams = {
        farmingTypeId: this.farmingTypeId,
        ownerId: this.userId,
        landId: this.landId,
        startDate: this.searchDate && this.searchDate[0] ? dayjs(this.searchDate[0]).format('YYYY-MM-DD') : '',
        endDate: this.searchDate && this.searchDate[1] ? dayjs(this.searchDate[1]).format('YYYY-MM-DD') : ''
      };
      this.pageInfo.currentPage = 1;
      this.getList();
    }
  }
};
</script>
<style scoped lang="scss">
.flex-row {
  display: flex;
  flex-direction: row;
  align-items: center;

  .span {
    font-size: inherit;
    font-size: 12px;
    width: 60px;
  }
}
.bottom12 {
  margin-bottom: 12px;
}
.patrol-img {
  width: 40px;
  height: 40px;
  border-radius: 40px;
  cursor: pointer;
}
</style>
